import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/new-design/Layout"
// import { HeartIcon } from "@heroicons/react/outline"
// import { Fragment } from "react"

import { Cards } from "../components/template"
import Seo from "../components/seo"
// import Title from "../components/sections/title"
import HeadSection from "../components/new-design/HeadSection"
// import { GatsbyImage } from "gatsby-plugin-image"
export default function Page({
  location,
  // pageContext: { data },
  data: {
    allSanityCar: { nodes: cars },
  },
}) {
  // const metaImage = {
  //   src: data.headerImage.asset?.url,
  //   width: data.headerImage.asset?.metadata?.dimensions?.width,
  //   height: data.headerImage.asset?.metadata?.dimensions?.height,
  // }
  // console.log(cars)
  return (
    <Layout>
      <Seo
        // template
        // image={metaImage}
        pathname={location.pathname}
        title="Cars"
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      {/* <Title title="Cars" /> */}
      <HeadSection
        // tagline="Finest cars"
        title="Cars"
        subtitle="A selection of our finest cars"
      />
      {/* <Hero property={data} /> */}
      <Cards className="py-12" template link="/cars/" list={cars} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityCar(sort: { order: ASC, fields: order }) {
      nodes {
        name
        highPrice
        lowPrice
        slug {
          _key
          _type
          current
        }
        cardImage {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
