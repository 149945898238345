import React from "react"

export default function HeadSection({ title, subtitle, tagline }) {
  return (
    <div className=" py-24 lg:py-32 bg-[#F6EFEC] flex flex-col items-center gap-2">
      {tagline && (
        <span className="text-sm relative before:absolute before:h-2 before:w-2 before:rounded-full before:bg-orange-600 before:-left-4 before:top-1.5">
          {tagline}
        </span>
      )}
      {title && (
        <h1 className="font-sans font-semibold text-stone-800">{title}</h1>
      )}
      {subtitle && <p className="text-stone-600">{subtitle}</p>}
    </div>
  )
}
